import { graphql } from "gatsby"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Hero } from "../components/Hero"
import { Container, Section } from "../components/Layouts"
import Portfolio from "../components/Portfolio/Portfolio"
import WhatOurClientsSay from "../components/Services/clients"
import Contact from "../components/Services/contact"

function Services(props) {
  const post = props.data.allUniquePagesJson.nodes[0]
  const {
    heroSection: { heading, blurb, desktopImage, mobileImage }
  } = post

  return (
    <Layout navSpacer className="services" path={props.path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <Hero
        noOverlapMobile
        heading={heading}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        blurb={blurb}
      />
      <Portfolio />
      <Section>
        <Container>
          <WhatOurClientsSay />
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <Contact />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query servicesPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          heading
          blurb
          desktopImage
          mobileImage
        }
      }
    }
  }
`

export default Services
